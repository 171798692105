.cardContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 0;
  max-width: 80%;
}

.cardImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 20px;
}

.cardItem {
  min-width: 100%;
  position: relative;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #F0F2F5;
  padding: 10px;
  margin: 10px;
  border-radius: 12px;
  width: 100%;

}

/* .content {} */

.cardName {
  font-size: 18px;
  font-weight: 700;
  /* color: #20498f; */
  color: #000;
  /* font-weight: normal; */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.replyback{
  color: #EF4F69;
}
.cardComment {
  font-weight: normal;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

.cardBottomItem {
  display: flex;
}

.cardBottomRightItem {
  /* max-width: 60%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.17);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: absolute;
  padding: 0px 2px;
  bottom: -8px;
  right: -30px;
  cursor: pointer;
}

.cardBottomLeftItem {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  gap: 10px;
  padding-left: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: absolute;
  /* padding: 0px 2px; */
  /* bottom: 0px; */
  bottom: -16px;
  left: 10px;
  color: gray;
}

.cardLikeBtn {
  width: 50%;
  border: none;
  color: #000000;
}

.cardLikeBtn:hover {
  color: rgb(159, 164, 173);
  cursor: pointer;
}

.reactImgContainer {
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
}

.reactImg {
  height: 20px;
  cursor: pointer;
}

.likeCounter {
  font-size: 14px;
  color: rgb(107, 107, 107);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* .reactImg:hover {
  opacity: .6;
  transition: transform 200ms ease;
  scale: 1.3;
} */

.delete-button {
  width: 30px;
  height: 30px;
  object-fit: cover;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
}


@media (max-width: 480px) {
  .cardBottomRightItem {
    padding: 0px;

  }

  .cardItem {
    min-width: 100%;
    position: relative;
  }




}